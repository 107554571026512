import {prepareServerSideValidationErrors} from '@/helper/FormHelper';

export default {
  methods: {
    async postProfileDocumentMisc(profileDocument) {
      const createdProfileDocument = await this.$store.dispatch(
        'api/profileDocument/postProfileDocumentMisc',
        profileDocument
      );
      createdProfileDocument.fileInput = profileDocument.fileInput;
      createdProfileDocument.attachmentsToKeep = [];
      return this.dispatchProfileDocumentAttachments(createdProfileDocument);
    },
    postProfileDocumentAttachment(file, id) {
      return this.$store
        .dispatch(
          'api/profileDocumentAttachment/postProfileDocumentAttachment',
          {id, file}
        )
        .catch((e) => {
          if (e.response.status !== 422) {
            throw e;
          }
          const errors = prepareServerSideValidationErrors(e.response);
          this.$notifications.showNotification({
            message: `Fehler bei Upload von "${file.name}". ${errors.file}`,
            color: 'error',
            timeout: 5000,
          });
          this.failedUploads++;
        });
    },
  },
};
