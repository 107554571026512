
  import ScreenSize from '@/mixins/ScreenSize';
  import Events from '@/events/Events';
  import clearableTabIndex from '@/mixins/clearableTabIndex';
  import DialogsBaseSection from '@/components/sections/DialogsBaseSection.vue';
  import UploadMiscDocuments from '@/mixins/UploadMiscDocuments';
  import UploadResumeDocuments from '@/mixins/UploadResumeDocuments';

  export default {
    name: 'RegistrationForm',
    components: {
      RichText: () => import('@/components/elements/RichText'),
      CheckboxRichText: () => import('@/components/elements/CheckboxRichText'),
      DialogsBaseSection,
    },
    mixins: [
      clearableTabIndex,
      ScreenSize,
      UploadMiscDocuments,
      UploadResumeDocuments,
    ],
    props: {
      position: {
        type: String,
        required: false,
        default: 'NULL',
      },
      email: {
        type: String,
        required: false,
        default: '',
      },
      job: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        registrationData: {
          email: '',
          firstName: '',
          lastName: '',
          terms: false,
          advertisingConsent: null,
        },
        organizationLink: '',
        registerItems: [],
        organizationLinkText: '',
        currentOrganization: {},
        section: {},
        buttonsMenu: false,
        disabled: false,
        fileInput: [],
      };
    },
    async fetch() {
      const strapiSingleType = this.job
        ? 'registration-before-application-dialog'
        : 'registration-or-login-dialog';
      const section = await this.$cachedStrapi
        .find(strapiSingleType)
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });
      this.section = {
        title: section.registration_title,
        subline: section.registration_subline,
        intro_text: section.registration_intro_text,
        button_text: section.registration_button_text,
        checkbox_terms_text: section.registration_checkbox_terms_text,
        checkbox_advertising_consent:
          section.registration_checkbox_advertising_consent,
        checkbox_organization_link:
          section.registration_checkbox_organization_link,
      };
      this.registrationData.advertisingConsent = this.section
        .checkbox_advertising_consent
        ? false
        : null;
      this.registrationData.email = this.email;
      this.organizationLinkText = this.section.checkbox_organization_link;
      let organizationId = '';
      if (this.$cookies.get('organization_parameter')) {
        organizationId = this.$cookies.get('organization_parameter').id;
      } else if (
        this.$route.query.organization_profile_id &&
        this.$route.query.mtm_source &&
        this.$route.query.mtm_medium &&
        this.$route.query.mtm_group
      ) {
        organizationId = this.$route.query.organization_profile_id;
      }
      if (organizationId != '') {
        await this.$store
          .dispatch('api/organizationProfile/getOrganizationProfile', {
            id: organizationId,
          })
          .then((result) => {
            this.currentOrganization = result;
          });
      }
      if (this.currentOrganization && this.organizationLinkText) {
        this.organizationLinkText = this.organizationLinkText.replace(
          /%company_name/g,
          this.currentOrganization.name
        );
      }
    },
    computed: {
      organizationAvailable() {
        return (
          this.$cookies.get('organization_parameter') ||
          (this.$route.query.organization_profile_id &&
            this.$route.query.mtm_source &&
            this.$route.query.mtm_medium &&
            this.$route.query.mtm_group)
        );
      },
    },
    methods: {
      async registration() {
        this.disabled = true;
        if (this.organizationLink) {
          this.registrationData.assignToOrganization = this.$cookies.get(
            'organization_parameter'
          ).id;
        }
        await this.$store
          .dispatch(
            'api/candidateProfile/registerWithoutPassword',
            this.registrationData
          )
          .then(async (res) => {
            if (res.status === 200) {
              if (this.fileInput.length > 0) {
                await this.$auth.fetchUser();
                this.saveResumeFile();
              }
              this.$auth.setUserToken(
                res.data.promotionbasis.token,
                res.data.promotionbasis.refresh_token
              );
              this.$notifications.showNotification({
                message: this.$t('action.registration_or_login_reg_success'),
                color: 'success',
                timeout: 5000,
              });
              this.$emitWithClientData(
                this.job
                  ? Events.RegistrationBeforeApplicationStepFinished
                  : Events.RegistrationOrLoginStepFinished,
                {
                  step: 'Registration',
                  jobPostingId: this.job?.id,
                }
              );
              if (!this.job) {
                this.$emitWithClientData(Events.RegistrationOrLoginFinished, {
                  type: 'Registration',
                });
              }
              this.$emitWithClientData(Events.Registered, {
                channel: 'default',
                position: this.position,
                advertisingConsent: this.registrationData.advertisingConsent,
                type: this.job
                  ? 'RegistrationBeforeApplication'
                  : 'RegistrationOrLogin',
              });
              if (this.job) {
                await this.$localforage.setItem('SavedApplyJob', this.job);
              }

              const queryParams = {...this.$route.query};
              if (this.$route.query.registration) {
                delete queryParams.registration;
              }
              if (this.job) {
                queryParams.successRegister = 1;
              } else {
                queryParams.success = 1;
              }
              this.$router.push(
                this.localePath({
                  name: this.$router.url,
                  query: queryParams,
                })
              );
            }
          })
          .catch((e) => {
            if (
              e &&
              e.response &&
              e.response.status &&
              e.response.status === 422
            ) {
              throw e;
            }
            if (
              e &&
              e.response &&
              e.response.status &&
              e.response.status === 400 &&
              e.response.data.promotionbasis.email
            ) {
              this.$notifications.showNotification({
                message: this.$t(
                  'action.registration_or_login_reg_error_org_email'
                ),
                color: 'error',
                timeout: 5000,
              });
            }
          });
        setTimeout(() => (this.disabled = false), 3000);
      },
      resetDialog() {
        this.registrationData = {
          email: '',
          firstName: '',
          lastName: '',
          terms: false,
          advertisingConsent: false,
        };
      },
      closeDialog() {
        this.$emit('close-dialog', 'Registration');
        const queryParams = {...this.$route.query};
        if (this.$route.query.registration) {
          delete queryParams.registration;
          this.$router.push(
            this.localePath({
              name: this.$router.url,
              query: queryParams,
            })
          );
        }
      },
    },
  };
