import Events from '@/events/Events';

export default {
  methods: {
    saveResumeFile() {
      Promise.all([this.dispatchResumeDocument()]).then(() => {
        this.$auth.fetchUser().then(() => {
          this.$store.dispatch(
            'profileCompleteness/calculateProfileCompleteness'
          );
        });
        this.$emitWithClientData(Events.ProfileDocumentChanged);
      });
    },
    dispatchResumeDocument() {
      const promises = [];
      const profileDocument = {
        type: 'profile_document_type.cv',
        fileInput: this.fileInput,
      };
      promises.push(this.postProfileDocumentMisc(profileDocument));
      return Promise.all(promises);
    },
    dispatchProfileDocumentAttachments(profileDocument) {
      const promises = [];
      for (const file of this.fileInput) {
        promises.push(
          this.postProfileDocumentAttachment(file, profileDocument.id)
        );
      }
      return Promise.all(promises);
    },
  },
};
